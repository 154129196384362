<template>
<div>
    <b-row>
        <!-- <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS CLIENTES
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="fas fa-briefcase fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaAuditorias.length}}</span>
                            <br>
                            <span class="text-muted h6">Auditorias</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col> -->
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-filter"></i><span class="h5"> Filtrar por cliente </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <!-- <b-col cols="12" class="my-2 ">
                            <b-button block size="md" variant="custom" @click="modalSeleccionarCliente = true">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Seleccionar Cliente por Nombre
                            </b-button>
                        </b-col> -->
                        <!-- <b-col md="12">
                            <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                <b-form-group label="Seleccionar cliente:" class="mb-2">
                                    <v-select @input="seleccionarCliente()" :reduce="listaClientes => listaClientes.idSubCliente" label="razonSocial" placeholder="Seleccione una opción" :class="{'style-valid-select': filterBusqueda.idSubCliente  , 'style-invalid-select is-invalid': !!errors.length}" v-model="filterBusqueda.idSubCliente" :options="listaClientes">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="12">
                            <b-form-group label="Número de ruc:" class="mb-2">
                                <b-form-input :disabled="true" placeholder="Seleccione un cliente" v-model="filterBusqueda.numeroRuc"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <validation-provider name="codigo" rules="required" v-slot="{errors}">
                                <b-form-group label="Numero de cotizacion:" class="mb-2">
                                    <v-select :reduce="listaCotizaciones => listaCotizaciones.idCotizacionCertificacion" label="codigo" placeholder="Seleccione una opción" :class="{'style-valid-select': filterBusqueda.idCotizacionCertificacion  , 'style-invalid-select is-invalid': !!errors.length}" v-model="filterBusqueda.idCotizacionCertificacion" :options="listaCotizaciones">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col> -->

                        <!-- <b-col md="6">
                            <validation-provider name="razón social" :rules="{ required: true }" v-slot="validationContext">
                                <b-form-group label="Razón social:" class="mb-2">
                                    <b-form-input :disabled="true" :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosNuevoCliente.razonSocial"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col> -->
                    </b-row>

                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de auditoria </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="6" class=" my-2 icon-custom ">
                            <i style="inline-size: auto" class="fas fa-briefcase fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaAuditorias.length}}</span>
                            <br>
                            <span class="text-muted h6">Auditorias</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5">Auditorías registradas </span>
                </CCardHeader>

                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">

                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaAuditorias" :fields="campoAuditorias" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #table-colgroup="campoAuditorias">
                                    <col v-for="field in campoAuditorias.fields" :key="field.key" :style="`width: ${field.width}`">
                                </template>

                                <template v-slot:cell(auditorLider)="data">
                                    <span> {{data.value | truncate(27)}}</span>
                                </template>

                                <template v-slot:cell(fechaInicio)="data">
                                    {{convertDateTime(data.item.fechaInicio).substr(0,10)}}
                                </template>
                                <template v-slot:cell(fechaFin)="data">
                                    {{convertDateTime(data.item.fechaFin).substr(0,10)}}
                                </template>
                                <template v-slot:cell(fechaCreacion)="data">
                                    {{convertDateTime(data.item.fechaCreacion)}}
                                </template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" class="mr-1 mb-1" variant="dark" v-c-tooltip.hover.click="'Ver detalles'" :to="{name: 'Nueva Auditoria', params: {id: param.item.idAuditoria}}">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <!-- <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCliente(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button> -->
                                    <b-button size="sm" class="mr-1 mb-1" @click="param.toggleDetails" v-c-tooltip.hover.click="'Ver detalles'" variant="blue">
                                        <span class="btn-inner--icon">
                                            <i :class="!param.detailsShowing  ? 'fas fa-eye' : 'fas fa-eye-slash' " class="my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                </template>
                                <template #row-details="row">
                                    <b-card>
                                        <b-row>
                                            <b-col sm="2">
                                                <b>Código de la certificación:</b>
                                            </b-col>
                                            <b-col sm="10 mb-1">
                                                {{row.item.codigo}}
                                            </b-col>
                                            <b-col sm="1">
                                                <b>Normas:</b>
                                            </b-col>
                                            <b-col sm="11 mb-1">
                                                {{String('* '+listaNormas.filter((x) => row.item.normas.includes(x.idNorma)).map(y => ` ${y.descripcion}, `).join(' * '))}}
                                            </b-col>
                                            <b-col sm="12">
                                                <b>Progreso:</b>
                                            </b-col>
                                            <b-col sm="12" class="mt-1 mb-2">
                                                <b-progress show-value striped :max="100" style="height:20px;">
                                                    <b-progress-bar v-if="JSON.parse(row.item.seguimiento)[0].contratosYdocumentos == 1" :animated="true" variant="dark" label="30% (Contratos y documentos)" :value="30"></b-progress-bar>
                                                    <b-progress-bar v-if="JSON.parse(row.item.seguimiento)[0].checkListInicial == 1" :animated="true" variant="success" label="20% (Check list)" :value="20"></b-progress-bar>
                                                    <b-progress-bar v-if="JSON.parse(row.item.seguimiento)[0].packStage1 == 1" :animated="true" variant="primary" label="25% (Stage 1)" :value="25"></b-progress-bar>
                                                    <b-progress-bar v-if="JSON.parse(row.item.seguimiento)[0].packStage2 == 1" :animated="true" variant="primary" label="25% (Stage 2)" :value="25"></b-progress-bar>
                                                </b-progress>
                                            </b-col>
                                        </b-row>
                                        <b-row class="mt-4">
                                            <b-col sm="12">
                                                <b-button class="mr-1 mb-1 float-right" variant="blue" size="sm" @click="row.toggleDetails"><i class="fas fa-eye-slash mr-2" /> Cerrar detalles</b-button>
                                                <b-button size="sm" class="mr-1 mb-1 float-right" variant="primary" @click="abrirModalDocumentosAuditoria(row.item)">
                                                    <span class="btn-inner--icon">
                                                        <i class="fas fa-cog  my-0 mx-0"></i> Carga de formatos de auditoria
                                                    </span>
                                                </b-button>
                                                <!--
                                                    - Debe pasar el array de tabs permitidos y validar posteriormente
                                                    - Debe guardar como nuevo item enst pero tendria idAuditoria
                                                    - Luego de que se guarde se debe poder abrir pero esta vez no va obtener la platilla sino ya el registro creado
                                                    - 
                                                    -      b bv v   

                                                    -->
                                                <b-button size="sm" class="mr-1 mb-1 float-right" variant="success" @click="lanzarFormularioInicial(row.item)">
                                                    <span class="btn-inner--icon">
                                                        <i class="fas fa-list-alt  my-0 mx-0"></i>
                                                        <span v-if="row.item.idPackCheckListInicial">
                                                            Editar carga de formatos iniciales
                                                        </span>
                                                        <span v-else>
                                                            Nueva carga de formatos iniciales
                                                        </span>
                                                    </span>
                                                </b-button>
                                                <b-button size="sm" class="mr-1 mb-1 float-right" variant="dark" :to="{name:'Contratos y Documentos', params: {idAuditoria: row.item.idAuditoria}}">
                                                    <span class="btn-inner--icon">
                                                        <i class="fas fa-file-alt  my-0 mx-0"></i> Contratos y Documentos
                                                    </span>
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-card>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>

                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" class="my-0 float-right" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" color="dark" :show.sync="modalDocumentosAuditoria" size="xl">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Documentos de auditoria </span>
            </h6>
            <CButtonClose @click="modalDocumentosAuditoria = false" class="text-white" />
        </template>
        <b-row>
            <b-col md="12" class="mb-3">
                <span class="text-muted h5"><u>Fase 1 - Documentos Auditoria</u></span>
            </b-col>
            <b-col md="12">
                <b-table bordered hover show-empty small responsive outlined :items="rowSelected.documentosStage1" :fields="camposDocumentosPack" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                    <template #table-colgroup="camposDocumentosPack">
                        <col v-for="field in camposDocumentosPack.fields" :key="field.key" :style="`width: ${field.width}`">
                    </template>
                    <template v-slot:cell(esquema)="param">
                        <span v-if="param.item.data">{{param.item.data.label}}</span>
                    </template>
                    <template v-slot:cell(opciones)="param">
                        <b-button v-if="param.item.data" size="sm" class="mr-1 mb-1" @click="param.toggleDetails" v-c-tooltip.hover.click="'Ver detalles'" variant="blue">
                            <span class="btn-inner--icon">
                                <i :class="!param.detailsShowing  ? 'fas fa-eye' : 'fas fa-eye-slash' " class="my-0 mx-0"></i>
                            </span>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card>
                            <b-row>
                                <b-col sm="3">
                                    <b>Normas / Tipo de documento:</b>
                                </b-col>
                                <b-col sm="9 mb-1">
                                    <span v-if="row.item.data">{{(row.item.data.label).split('|')[0]}}</span>
                                </b-col>
                                <b-col sm="3">
                                    <b>Fecha de creación:</b>
                                </b-col>
                                <b-col sm="9 mb-1">
                                    <span v-if="row.item.data">{{(row.item.data.label).split('|')[1]}}</span>
                                </b-col>
                                <b-col sm="12" >
                                    <b-button block size="md" variant="custom" @click="modalNuevoPersonal = true">
                                         <span class="btn-inner--icon">
                                            <i class="fas fa-cog  my-0 mx-0"></i> Observaciones
                                        </span>
                                    </b-button>
                                
                                </b-col>
                                <CModal :closeOnBackdrop="false" color="primario" size="lg" :show.sync="modalNuevoPersonal">
                                    <template #header>
                                        <h6 class="modal-title">
                                           <span class="btn-inner--icon">
                                            <i class="fas fa-cog  my-0 mx-0"></i> Observaciones
                                        </span>
                                        </h6>
                                        <CButtonClose @click="modalNuevoPersonal = false" class="text-white" />
                                    </template>

                                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                        <b-form @submit.stop.prevent="handleSubmit(nuevaObservacion)">
                                            <b-row>
                                               
                                                <b-col md="12">
                                                        <b-form-group label="Observaciones:" class="mb-2">
                                                            <b-form-textarea :enabled="!user.uidProfile != '45f0ec93-8762-4071-b6db-dbce75fddb55'" v-model="datosNuevoPersonal.Observaciones" rows="3" max-rows="6" placeholder="Ingrese observaciones" class="disabled-white" ></b-form-textarea>
                                                        </b-form-group>
                                                </b-col>

                                              

                                                <b-col class="my-2 text-right" md="12" v-if=" user.uidProfile == '2d2cefc6-2635-47cc-8935-699fb1051a1f'">
                                                    <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                                                        Guardar
                                                    </b-button>
                                                    <b-button size="md" variant="danger" @click="modalNuevoPersonal = false">
                                                        Cancelar
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </validation-observer>
                                    <template #footer>
                                        <span></span>
                                    </template>
                                </CModal>
                                <b-col sm="12">
                                    <b-button class="mr-1 mb-1 float-right" variant="blue" size="sm" @click="row.toggleDetails"><i class="fas fa-eye-slash mr-2" /> Cerrar detalles</b-button>
                                    <b-button v-if="rowSelected.seguimiento[0].packStage1 != 1" size="sm" class="mr-1 mb-1 float-right" variant="dark" @click="goFormat('i',row.item.data,row.item,rowSelected.seguimiento.packStage1)">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-arrow-circle-right  my-0 mx-0"></i> Llenar formato
                                        </span>
                                    </b-button>
                                </b-col>
                                <b-col sm="12" v-if="row.item.formatos.length > 0">
                                    <div class="accordion" role="tablist">
                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 " style="background: #2d2d52;color:#ffffff" role="tab">
                                                <div block v-b-toggle="`accordion-${row.index}`" class="p-1">
                                                    <span class=" h6"> Lista de formatos</span>
                                                    <span class="float-right">
                                                        <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse :id="`accordion-${row.index}`" accordion="my-accordion" role="tabpanel">
                                                <b-card-body class="pt-0 pb-0 pl-0 pr-0">
                                                    <b-list-group flush>
                                                        <b-list-group-item @click="goFormat('u', i, row.item,rowSelected.seguimiento[0].packStage1)" v-for="(i, index) in row.item.formatos" :key="index" href="#">
                                                            <span>{{ row.item.descripcion }} |
                                                                {{ convertDateTime(i.fechaCreacion) }} |
                                                                {{ i.sUsuarioCreador }}</span>
                                                        </b-list-group-item>
                                                    </b-list-group>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>
                </b-table>
            </b-col>
            <b-col md="12">
                <b-button class="float-right" v-if="rowSelected.seguimiento[0].packStage1 != 1" :disabled="disabled" size="md" variant="success" @click="finalizarDocumentosAuditoria(1)"><i class="fas fa-save mr-1"></i>Finalizar Stage 1</b-button>
            </b-col>
            <b-col md="12" class="mb-3">
                <span class="text-muted h5"><u>Fase 2 - Documentos Auditoria</u></span>
            </b-col>
            <b-col md="12">
                <b-table bordered hover show-empty small responsive outlined :items="rowSelected.documentosStage2" :fields="camposDocumentosPack" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                    <template #table-colgroup="camposDocumentosPack">
                        <col v-for="field in camposDocumentosPack.fields" :key="field.key" :style="`width: ${field.width}`">
                    </template>
                    <template v-slot:cell(esquema)="param">
                        <span v-if="param.item.data">{{param.item.data.label}}</span>
                    </template>
                    <template v-slot:cell(opciones)="param">
                        <b-button v-if="param.item.data" size="sm" class="mr-1 mb-1" @click="param.toggleDetails" v-c-tooltip.hover.click="'Ver detalles'" variant="blue">
                            <span class="btn-inner--icon">
                                <i :class="!param.detailsShowing  ? 'fas fa-eye' : 'fas fa-eye-slash' " class="my-0 mx-0"></i>
                            </span>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card>
                            <b-row>
                                <b-col sm="3">
                                    <b>Normas / Tipo de documento:</b>

                                </b-col>
                                <b-col sm="9 mb-1">
                                    <span v-if="row.item.data">{{(row.item.data.label).split('|')[0]}}</span>
                                </b-col>
                                <b-col sm="3">
                                    <b>Fecha de creación:</b>
                                </b-col>
                                <b-col sm="9 mb-1">
                                    <span v-if="row.item.data">{{(row.item.data.label).split('|')[1]}}</span>
                                </b-col>
                                <b-col sm="12">
                                    <b-button class="mr-1 mb-1 float-right" variant="blue" size="sm" @click="row.toggleDetails"><i class="fas fa-eye-slash mr-2" /> Cerrar detalles</b-button>
                                    <b-button v-if="rowSelected.seguimiento[0].packStage2 != 1" size="sm" class="mr-1 mb-1 float-right" variant="dark" @click="goFormat('i',row.item.data,row.item)">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-arrow-circle-right  my-0 mx-0"></i> Llenar formato
                                        </span>
                                    </b-button>
                                </b-col>
                                <b-col sm="12" v-if="row.item.formatos.length > 0">
                                    <div class="accordion" role="tablist">
                                        <b-card no-body class="mb-1">
                                            <b-card-header header-tag="header" class="p-1 " style="background: #2d2d52;color:#ffffff" role="tab">
                                                <div block v-b-toggle="`accordion-${row.index}`" class="p-1">
                                                    <span class=" h6"> Lista de formatos</span>
                                                    <span class="float-right">
                                                        <i class="fas" style="font-size:1.1rem"></i></span>
                                                </div>
                                            </b-card-header>
                                            <b-collapse :id="`accordion-${row.index}`" accordion="my-accordion" role="tabpanel">
                                                <b-card-body class="pt-0 pb-0 pl-0 pr-0">
                                                    <b-list-group flush>
                                                        <b-list-group-item @click="goFormat('u', i, row.item,rowSelected.seguimiento[0].packStage2)" v-for="(i, index) in row.item.formatos" :key="index" href="#">
                                                            <span>{{ row.item.descripcion }} |
                                                                {{ convertDateTime(i.fechaCreacion) }} |
                                                                {{ i.sUsuarioCreador }}</span>
                                                        </b-list-group-item>
                                                    </b-list-group>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>
                </b-table>
            </b-col>
            <b-col md="12">
                <b-button class="float-right" v-if="rowSelected.seguimiento[0].packStage2 != 1" :disabled="disabled" size="md" variant="success" @click="finalizarDocumentosAuditoria(2)"><i class="fas fa-save mr-1"></i>Finalizar Stage 2</b-button>
            </b-col>
        </b-row>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {

    data() {
        return {
            disabled: false,
            modalNuevoPersonal: false,
            modalDocumentosAuditoria: false,
            listaAuditorias: [],
            rowSelected: {
                idAuditoria: null,
                seguimiento: [{
                    contratosYdocumentos: 1,
                    checkListInicial: 1,
                    packStage1: 1,
                    packStage2: 1
                }],
                documentosStage1: [],
                documentosStage2: [],
            },
            camposDocumentosPack: [{
                key: "index",
                label: "N°",
                class: "text-center",
                width: "5%"
            }, {
                key: "categoria",
                label: "Categoría",
                class: "text-center",
                width: "20%"
            }, {
                key: "descripcion",
                label: "Documento",
                class: "text-center",
                width: "20%"
            }, {
                key: "Esquema",
                label: "Esquema",
                class: "text-center",
                width: "50%"
            }, {
                label: "",
                key: "opciones",
                class: "text-center",
                width: "5%"
            }, ],
            datosNuevoPersonal: {
               
                Observaciones: '',
            },
            acions: [],
            comboPuestosTrabajo: [],
            campoAuditorias: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                    width: "5%"
                },
                {
                    key: "razonSocial",
                    label: "Empresa",
                    class: "text-center",
                    width: "35%"
                },
                {
                    key: "fechaInicio",
                    label: "Fecha de inicio",
                    class: "text-center",
                    width: "12%"
                },
                {
                    key: "fechaFin",
                    label: "Fecha fin",
                    class: "text-center",
                    width: "12%"
                },
                {
                    key: "fechaCreacion",
                    label: "Fecha de creación",
                    class: "text-center",
                    width: "12%"
                },
                {
                    label: "",
                    key: "opciones",
                    class: "text-center",
                    width: "12%"
                },
            ],

            filasTotales: 0,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],

            routersList: [{
                idPack: 1,
                route: 'eu Gestión plan de auditoría stage 1'
            }, {
                idPack: 2,
                route: 'eu Gestion asignacion equipo auditor stage 1'
            }, {
                idPack: 3,
                route: 'eu Gestion estudios conflictos intereses stage 1'
            }, {
                idPack: 4,
                route: 'eu Gestión report stage 1'
            }, {
                idPack: 5,
                route: 'au Gestión plan de auditoría stage 2'
            }, {
                idPack: 6,
                route: 'au Gestion asignacion equipo auditor stage 2'
            }, {
                idPack: 7,
                route: 'au Gestion estudios conflictos intereses stage 2'
            }, {
                idPack: 8,
                route: 'au Gestión checkList stage 2'
            }, {
                idPack: 9,
                route: 'au Gestión report stage 2'
            }, {
                idPack: 10,
                route: 'au Gestión Tecnical Report stage 2'
            }, {
                idPack: 11,
                route: 'au Gestión Cliente feedback stage 2'
            }, {
                idPack: 12,
                route: 'au Gestión Reporte nc stage 2'
            }, {
                idPack: 13,
                route: 'au Gestión audit team approval stage 2'
            }, ],
            user: {}
            // filterBusqueda: {
            //     idSubCliente: null,
            //     numeroRuc: '',
            //     idCotizacionCertificacion: ''
            // }

            // datosNuevoCliente: {
            //     idCliente: '',
            //     numeroRuc: '',
            //     razonSocial: '',
            //     estado: 2,
            // },
            // disabled: false
        }
    },

    methods: {
        goFormat(eu, params, obj, block) {
            if (block == 1) {
                this.swat('info', 'Proceso finalizado, ya no se puede editar')
            } else {
                let k = Object.keys(obj.data)[1];
                this.$router.push({
                    name: this.routersList.filter(el => el.idPack == obj.idPack)[0].route,
                    params: {
                        id: params[k],
                        eu
                    },
                    query: {
                        idAuditoria: this.rowSelected.idAuditoria
                    }
                })
            }
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        convertDateTime(datetime) {
            return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
        },

        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        // guardarLocalFilter() {
        //     localStorage.setItem('filterBusqueda', JSON.stringify(this.filterBusqueda))
        // },
        resetModalNuevoPersonal() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosNuevoPersonal.Observaciones = '';
           
        },
        abrirModalDocumentosAuditoria(auditoria) {
            // this.rowSelected = 
            this.rowSelected.idAuditoria = auditoria.idAuditoria;
            this.rowSelected.seguimiento = JSON.parse(auditoria.seguimiento);
            this.rowSelected.documentosStage1 = JSON.parse(auditoria.referenciasDocumentosPack).filter(el => el.categoria == 'Documentos Stage 1' || el.categoria == 'Ingreso de documentos Stage 1');
            this.rowSelected.documentosStage2 = JSON.parse(auditoria.referenciasDocumentosPack).filter(el => el.categoria == 'Documentos Stage 2' || el.categoria == 'Ingreso de documentos Stage 2');
            this.modalDocumentosAuditoria = true;
        },
        lanzarFormularioInicial(params) {
            // console.log(params)
            // return 

            let arrOrg = JSON.parse(params.cargaModuloOrganizacional)
            let arrGes = JSON.parse(params.cargaModuloGestion);
            let arr = [...arrOrg, ...arrGes]

            localStorage.setItem('navs', JSON.stringify(arr))
            this.$router.push({
                name: 'Formato lista de verificacion inicial',
                params: {
                    idFormato: params.idPackCheckListInicial ? params.idPackCheckListInicial : params.referenciasCheckListIniciales,
                    eu: params.idPackCheckListInicial ? "u" : "i"
                },
                query: {
                    idAuditoria: params.idAuditoria
                }
            })

        },
        finalizarDocumentosAuditoria(stage) {
            let me = this;
            let seguimiento = [...me.rowSelected.seguimiento];
            if (stage == 1) {
                seguimiento[0].packStage1 = 1
            } else {
                seguimiento[0].packStage2 = 1
            }
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/auditorias/actualizar-seguimiento-auditoria", {
                        idAuditoria: me.rowSelected.idAuditoria,
                        seguimiento
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarAuditorias(null);
                    setTimeout(() => {
                        let auditoria = me.listaAuditorias.filter(el => el.idAuditoria == me.rowSelected.idAuditoria)[0]
                        me.abrirModalDocumentosAuditoria(auditoria)
                    }, 300)
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' + error)
                    me.disabled = false;
                });
        },
          nuevaObservacion() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/registrar-observaciones-iqc",
                    me.datosNuevoPersonal, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.disabled = false;
                    if (response.data.alert) {
                        return me.swat(color, response.data.mensaje)
                    }
                    me.swat(color, response.data.mensaje)
                    me.modalNuevoPersonal = false;
                    // me.listarPersonal();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        // obtenerCheckListInicial() {
        //     let me = this;
        //     axios.defaults.headers.common[
        //         "Authorization"
        //     ] = `Bearer ${localStorage.token}`;
        //     axios
        //         .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/obtener-pack-checklist-inicial", {
        //             params: {
        //                 idPackCheckListInicial: me.$route.params.idFormato,
        //                 idCliente: me.dataCheckListInicial.idCliente
        //             }
        //         })
        //         .then(function (response) {
        //             me.dataCheckListInicial.idPackCheckListInicial = response.data[0][0].idPackCheckListInicial;
        //             me.dataCheckListInicial.idAuditoria = response.data[0][0].idAuditoria;
        //             me.dataCheckListInicial.idCliente = response.data[0][0].idCliente;

        //         })
        //         .catch(function (error) {
        //             me.swat('error', 'Algo salió mal!')
        //             me.$router.push({
        //                 name: 'Check List de Informacion'
        //             })
        //         });
        // },

        listarAuditorias(idCotizacionCertificacion) {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/auditorias/listar-auditorias", {
                    params: {
                        idCotizacionCertificacion,
                    }
                })
                .then(function (response) {
                    me.listaAuditorias = []
                    //administrador -> 45f0ec93-8762-4071-b6db-dbce75fddb55
                    //si vas agregar mas perfiles que van a tener todo el listado 
                    //revisar id de la tabla de TipoPerfiles de la BD y validar
                    //ejem: if(me.user.uidProfile !='abc1' && me.user.uidProfile != 'abc2'...

                    //revisor = 2d2cefc6-2635-47cc-8935-699fb1051a1f
                    if (me.user.uidProfile != '45f0ec93-8762-4071-b6db-dbce75fddb55') {
                        for (let k in response.data) {
                            let listaAdmitida = JSON.parse(response.data[k].personalAuditoria).map(el => el.idPersona)
                            if (listaAdmitida.includes(me.user.uidPerson)) {
                                me.listaAuditorias.push(response.data[k])
                            }
                        }
                    } else {
                        me.listaAuditorias = response.data
                    }
                    me.filasTotales = me.listaAuditorias.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!' + error)
                });
        },
        // eliminarCliente(param) {
        //     let me = this
        //     me.$swal.fire({
        //         title: '¿Estas seguro de eliminar la auditoría?',
        //         text: "¡No podrás revertir esto!",
        //         icon: 'warning',
        //         showCancelButton: false,
        //         confirmButtonColor: '#d33',
        //         confirmButtonText: 'Eliminar',
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             axios
        //                 .post(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/auditorias/eliminar-auditoria", {
        //                     idAuditoria: param.item.idAuditoria
        //                 }, {
        //                     headers: {
        //                         Authorization: `Bearer ${localStorage.token}`,
        //                     }
        //                 })
        //                 .then(function (response) {
        //                     let color = response.data.resultado == 1 ? "success" : "error";
        //                     me.swat(color, response.data.mensaje)
        //                     me.listarAuditorias(me.filterBusqueda.idCotizacionCertificacion)
        //                 })
        //                 .catch(function (error) {
        //                     me.swat('error', 'Algo salió mal!')
        //                 });
        //         }
        //     })
        // },
        // listarCotizacionIQC(idSubCliente) {
        //     let me = this;
        //     axios.defaults.headers.common[
        //         "Authorization"
        //     ] = `Bearer ${localStorage.token}`;
        //     axios
        //         .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/auditorias/listar-cotizacion-iqc", {
        //             params: {
        //                 idSubCliente: idSubCliente
        //             }
        //         })
        //         .then(function (response) {
        //             me.listaCotizaciones = response.data;
        //             if (me.listaCotizaciones.length > 0) {
        //                 me.filterBusqueda.idCotizacionCertificacion = me.listaCotizaciones[0].idCotizacionCertificacion
        //                 me.listarAuditorias(me.listaCotizaciones[0].idCotizacionCertificacion);
        //             }
        //         })
        //         .catch(function (error) {
        //             me.swat('error', 'Algo salió mal!')
        //         });
        // },

        // listarClientes() {
        //     let me = this;
        //     axios.defaults.headers.common[
        //         "Authorization"
        //     ] = `Bearer ${localStorage.token}`;
        //     axios
        //         .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/listar-clientes-iqc", {
        //             params: {
        //                 estado: 2,
        //                 idCliente: me.$store.state.user.uidClient
        //             }
        //         })
        //         .then(function (response) {
        //             me.listaClientes = response.data;
        //         })
        //         .catch(function (error) {
        //             me.swat('error', 'Algo salió mal!')
        //         });
        // },

        // seleccionarCliente() {
        //     //clean variables
        //     this.listaAuditorias = [];
        //     this.filterBusqueda.idCotizacionCertificacion = null

        //     let clienteSeleccionado = this.listaClientes.filter(x => x.idSubCliente == this.filterBusqueda.idSubCliente)

        //     this.filterBusqueda.numeroRuc = clienteSeleccionado[0].numeroRuc
        //     let idSubCliente = clienteSeleccionado[0].idSubCliente
        //     this.listarCotizacionIQC(idSubCliente)n m

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },
    watch: {
        modalNuevoPersonal: function (val) {
            if (val == false) {
                this.resetModalNuevoPersonal();
            }
        },
        // 'filterBusqueda.idSubCliente': function (val) {
        //     if (val) {
        //         this.guardarLocalFilter();
        //     }
        // },
        // 'filterBusqueda.idCotizacionCertificacion': function (val) {
        //     if (val) {
        //         this.guardarLocalFilter();
        //         this.listarAuditorias(this.filterBusqueda.idCotizacionCertificacion)
        //     }
        // },

    },
    mounted() {
        if (this.$store.state.user) {
            this.user = this.$store.state.user;

            this.listarAuditorias(null);
        }
        // this.listarClientes();
        // if (localStorage.getItem('filterBusqueda')) {
        //     this.filterBusqueda = JSON.parse(localStorage.getItem('filterBusqueda'))
        //     if (this.filterBusqueda.idCotizacionCertificacion) {
        //         this.listarCotizacionIQC(this.filterBusqueda.idSubCliente)
        //     }
        // }
    }
}
</script>
